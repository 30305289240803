<script lang="ts">
  import { BlogCategoryEnum } from '$lib/domain/blog-category.enum';
	import type { Blog } from '$lib/domain/blog.interface';
	import type { Article } from '$lib/domain/article.interface';

	export let article: Blog | Article;
	export let showChapter: true|false = true;
	export let showTag: true|false = true;
	export let categoryTag = 0;
</script>

<article
	style={`background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${ article.caseStudyTitle
	? `/articles/`+ article.thumbnailImage
	: `/blog/blog-thumb/${article.url.replaceAll('/', '')}.webp` }');`}>
	<div class="card-top">
		<div class="top-left">
			{#if showChapter}
				<p>{article.caseStudyTitle ? 'Case study' : 'Blog'}</p>
			{/if}
		</div>
		{#if showTag}
			<div class="top-right">
				{BlogCategoryEnum[categoryTag]}
			</div>
		{/if}
	</div>
	<div class="spacer">
		<span>{article.title}</span>
	</div>
</article>

<style lang="scss">
	@import '$styles/vars';

	article {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 308px;
		color: $main-white;
		padding: 24px;
		backdrop-filter: blur(12px);
		border: 4px solid ;
		border-radius: 12px;
		box-sizing: border-box;
		
		span {
			font-size: 18px;
			text-align: center;
			margin: 0;
			font-weight: 700;
      line-height: 22px;
		}

		&:hover {
			border: 4px solid $green;
			.spacer {
				color: $green
			}
		}

		.card-top {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.top-right {
				padding: 2px 6px;
				font-size: 14px;
				font-weight: 600;
				line-height: 28px;
				border-radius: 6px;
				background: $green;
			}
		}

		p {
			font-size: 14px;
			font-weight: 600;
			line-height: 28px;
			text-align: center;
			margin: 0;
		}

		.spacer {
			height: 100%;
			display: flex;
			margin: auto;
		}
	}

	@media (max-width: $portrait-phone-size) {

		article {
			.card-top {
				.top-left, .top-right{
					font-size: 12px;
				}
			}
		}
	}
</style>
